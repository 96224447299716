import request from '@/utils/request'

// 科目余额
export function printSetOne(data) {
  return request({
    url: 'api/v2/printSet/one',
    method: 'post',
    data
  })
}
// 科目余额打印
export function accountBook(data) {
  return request({
    url: 'api/v2/print/accountBook',
    method: 'post',
    data
  })
}

// 导出
export function exportBook(data) {
  return request({
    url: 'api/v2/export/accountBook',
    method: 'post',
    data
  })
}
// 税表打印
export function report(data) {
  return request({
    url: 'api/v2/print/report',
    method: 'post',
    data
  })
}

// 税表导出
export function exportReport(data) {
  return request({
    url: 'api/v2/export/report',
    method: 'post',
    data
  })
}

// 工资表打印
export function printSalary(data) {
  return request({
    url: 'api/v2/print/salary',
    method: 'post',
    data
  })
}

// 记账客户导出
export function assignCustomers(data) {
  return request({
    url: 'api/v2/export/assignCustomers',
    method: 'post',
    data
  })
}

// 库存汇总表打印
export function inventoryDetail(data) {
  return request({
    url: 'api/v2/print/inventoryDetail',
    method: 'post',
    data
  })
}

// 出入库明细打印
export function InOutInventoryDetail(data) {
  return request({
    url: 'api/v2/print/InOutInventoryDetail',
    method: 'post',
    data
  })
}

// 出入库明细打印
export function printFixedAssets(data) {
  return request({
    url: 'api/v2/print/printFixedAssets',
    method: 'post',
    data
  })
}

//打印采购明细进项
export function dayinSellIn(data) {
  return request({
    url: 'api/v2/print/dayinSellIn',
    method: 'post',
    data
  })
}

//打印采购明细销项
export function dayinSell(data) {
  return request({
    url: 'api/v2/print/dayinSell',
    method: 'post',
    data
  })
}


//进销存明细的打印导出
export function detailAccountInventoryOut(data) {
  return request({
    url: 'api/v2/print/detailAccountInventoryOut',
    method: 'post',
    data
  })
}

