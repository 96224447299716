<template>
  <div class="app-container">
    <div class="right_box">
      <!-- <el-button type="primary" @click="download()" size="small" plain>导出</el-button> -->
      <el-button size="small" @click="printingNew()" type="success">打印</el-button>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick" class="top_nav">
      <!-- 增值税减免税申报明细表 -->
      <el-tab-pane label="残疾人保障金" name="1">
        <div>
        <el-radio-group v-model="expStatus">
          <el-radio :label="true">使用公式</el-radio>
          <el-radio :label="false">不使用公式</el-radio>
        </el-radio-group>
      </div>
        <div class="big_box4">
          <div class="top_title3">
            <h4>残疾人保障金</h4>
            <!-- <h5>小规模纳税人适用</h5> -->
            <div class="bottom_font clearfix">
              <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
              <h6 class="center">税款所属期：<span>{{durationOfTaxTime}}</span></h6>
              <h6 class="right">
                <p>单位：元</p>
                <p>纳税人识别号：<span>{{taxNo}}</span></p>
              </h6>
            </div>
          </div>

          <table class="content" cellspacing="0">
            <tr class="top_bg">
              <td colspan="12" class="center weight">残疾人就业保障金缴费申报表</td>
            </tr>
            <tr class="top_bg">
              <td class="center">费款所属期起</td>
              <td class="center">费款所属期止</td>
              <td class="center">在职职工工资总额	</td>
              <td class="center">在职职工人数	</td>
              <td class="center">应安排残疾人就业比例</td>
              <td class="center">实际安排残疾人就业人数</td>
              <td class="center">在职职工平均工资</td>
              <td class="center">本期应纳税额</td>
              <td class="center">减免性质</td>

              <td class="center">本期减免税额</td>
              <td class="center">本期已缴税额</td>
              <td class="center">本期应补（退）税额</td>
              
            </tr>
            <tr>
              <td class="border_none" style="width:10%">
                <el-date-picker v-model="list[0].ssqq" value-format="YYYY-MM-DD" type="date" placeholder="选择日期" size="small">
                </el-date-picker>
              </td>
              <td class="border_none" style="width:10%">
                <el-date-picker v-model="list[0].ssqz" value-format="YYYY-MM-DD" type="date" placeholder="选择日期" size="small">
                </el-date-picker>
              </td>
              <qzf-td style="width:8%" :disabled="false" v-model:amount="list[0].zzzggze" @change="changeList()"></qzf-td>
              <qzf-td style="width:8%" :disabled="false" v-model:amount="list[0].zzzgrs" @change="changeList()"></qzf-td>
              <td style="width:6%">{{list[0].yapcjrjybl}}</td>
              <qzf-td style="width:8%" :disabled="false" v-model:amount="list[0].sjapcjrjybl" @change="changeList()"></qzf-td>
              <qzf-td style="width:8%" :disabled="expStatus" v-model:amount="list[0].zzzgpjgz" @change="changeList()"></qzf-td>
              <qzf-td style="width:8%" :disabled="expStatus" v-model:amount="list[0].bqynse" @change="changeList()"></qzf-td>

              <td style="width:8%">
                <el-select size="small" v-model="list[0].jmxz" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>

              </td>
              <qzf-td style="width:8%" :disabled="expStatus" v-model:amount="list[0].bqjmse" @change="changeList()"></qzf-td>
              <qzf-td style="width:8%" :disabled="false" v-model:amount="list[0].bqyjse" @change="changeList()"></qzf-td>
              <qzf-td style="width:8%" :disabled="expStatus" v-model:amount="list[0].bqybtse" @change="changeList()"></qzf-td>
            </tr>

        
          </table>
        </div>
      </el-tab-pane>
      <!-- 增值税减免税申报明细表 end -->
    </el-tabs>
    <div class="save_box">
      <el-button type="primary" @click="saveValues()" size="small" plain>保存</el-button>
    </div>
  </div>
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
import { report ,exportReport } from "@/api/printSet"

export default {
  name:'cbj',
  data() {
    return {
      expStatus: true,
      durationOfTaxTime: '',
      activeName: '1',
      name:'1',
      listQuery: {},
      list: [{"ssqq":'',"ssqz":'',"zzzggze":'',"zzzgrs":'',"yapcjrjybl":'',"sjapcjrjybl":'',"zzzgpjgz":'',"bqynse":'',"jmxz":'',"bqjmse":"","bqyjse":'',"bqybtse":''}],
      // list:[{},],
      period: '',
      options: [{
        value: '0035049901',
        label: '0035049901|在职职工总数30人（含）以下暂免征收残保金|《国家发展和改革委员会 财政部 民政部 人力资源和社会保障部 国家税务总局 中国残疾人联合会关于印发<关于完善残疾人就业保障金制度更好促进残疾人就业的总体方案>的通知》 发改价格规[2019]2015号附件：第二条第(四)款'
      },{
        value: '0035013601',
        label: '0035013601|残保金调整为分档征收|《国家发展和改革委员会 财政部 民政部 人力资源和社会保障部 国家税务总局 中国残疾人联合会关于印发<关于完善残疾人就业保障金制度更好促进残疾人就业的总体方案>的通知》 发改价格规[2019]2015号附件：第二条第(三)款'
      },{
        value: '0099021901',
        label: '0099021901|光伏发电免征政府性基金|《财政部关于对分布式光伏发电自发自用电量免征政府性基金有关问题的通知》 财综〔2013〕103号'
      },{
        value: '0099129999',
        label: '0099129999|其他|其他'
      },],
      comName:this.$store.getters['user/comInfo'].comName,
      taxNo:this.$store.getters['user/comInfo'].taxNo,
      param:{
        comIds:[this.$store.getters['user/comInfo'].comId],
        period:this.$store.getters['user/comInfo'].period,
        bookNames:['gs_cbj']
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // 公式
    changeList(){
      if(!this.expStatus){
        return
      }
      this.list[0].zzzgpjgz = this.list[0].zzzgrs != 0?(this.list[0].zzzggze/this.list[0].zzzgrs).toFixed(2)*1:0
      let aa = ((this.list[0].zzzgrs * this.list[0].yapcjrjybl - this.list[0].sjapcjrjybl) * this.list[0].zzzgpjgz).toFixed(2) * 1
      if(aa < 0){
        this.list[0].bqynse = 0
      }else{
        this.list[0].bqynse = aa
      }
      if(this.list[0].bqynse == 0){
        this.list[0].bqjmse = 0
      }else if(this.list[0].zzzgrs <= 30){
        this.list[0].bqjmse = this.list[0].bqynse
      }else if(this.list[0].sjapcjrjybl/this.list[0].zzzgrs >= 0.01 && this.list[0].sjapcjrjybl/this.list[0].zzzgrs <= this.list[0].yapcjrjybl){
        this.list[0].bqjmse = (this.list[0].bqynse * 0.5).toFixed(2) * 1
      }else if(this.list[0].sjapcjrjybl/this.list[0].zzzgrs < 0.01){
        this.list[0].bqjmse = (this.list[0].bqynse * 0.1).toFixed(2) * 1
      }
      this.list[0].bqybtse = (this.list[0].bqynse - this.list[0].bqjmse - this.list[0].bqyjse).toFixed(2) * 1

    },
    getList(){
      gsInfo({tableName: 'gs_cbj'}).then(res=>{
        if(res.data.data.info && res.data.data.info.length != 0 ){
          this.list = res.data.data.info
          this.durationOfTaxTime = res.data.data.time
        }
      })
    },
    saveValues(){
      let param = {
        tableName:'gs_cbj',
        items: this.list
      }
      param.items.map(v => {
        v.yapcjrjybl = Number(v.yapcjrjybl)
        v.sjapcjrjybl = Number(v.sjapcjrjybl)
        v.zzzggze = Number(v.zzzggze)
        v.zzzgrs = Number(v.zzzgrs)
        v.zzzgpjgz = Number(v.zzzgpjgz)
        v.bqynse = Number(v.bqynse)
        v.bqyjse = Number(v.bqyjse)
        v.bqybtse = Number(v.bqybtse)
        v.bqjmse = Number(v.bqjmse)
      })
      gsEdit(param).then(res => {
        if(res.data.msg == "success") {
          this.$qzfMessage("保存成功")
          this.getList()
        }
      })
    },
    printingNew(){
      report(this.param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    },
    download(){
      exportReport(this.param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
}
.center{
  text-align: center;
  color: #333 !important;
}
.weight{
  font-size: 14px !important;
  font-weight: 600;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.left_k{
  text-indent: 41px;
}
.content{
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  span{
    line-height: 28px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 14px;
  }
  input{
    width: 100%;
    line-height: 28px;
    padding: 0 6px;
    font-size: 14px;
    // text-align: right;
    // padding-right: 12px;
  }
}
.top_title3{
  width: 100%;
  margin: 0 auto;
  h4{
    font-size: 20px;
    color: #333;
    font-weight: 600;
    text-align: center;
  }
  h5{
    font-size: 16px;
    color: #333;
    font-weight: normal;
    text-align: center;
    margin-top: 10px;
  }
  .bottom_font{
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    h6{
      float: left;
      width: 33.33%;
      font-size: 14px;
      color: #333;
      font-weight: normal;
    }
    h6:nth-child(1){
      padding-top: 10px;
    }
    h6:nth-child(2  ){
      padding-top: 10px;
    }
  }
}

.big_box4{
  width: 98%;
  // padding: 40px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
}
.right_box {
  // float: right;
  width:25%;
  text-align: right;
  margin-bottom: 15px;
  margin-right: 16px;
  position: absolute;
  top: 10px;
  right: 3px;
  z-index:999
}
</style>